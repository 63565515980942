@import "../../styles/global.module";

.container {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  align-items: stretch;

  .listTitle {
    @extend .body;
    margin-bottom: $padding * 1px;
    text-transform: capitalize;
  }

  .currentUsers,
  .searchResults,
  .addRemoveUsers {
    display: flex;
    justify-content: flex-start;

    .pill {
      margin-bottom: $padding * 0.5px;

      &:not(:last-child) {
        margin-right: $padding * 0.5px;
      }
    }
  }

  .searchResults {
    flex-flow: column nowrap;
    align-items: stretch;

    .result {
      padding: $padding * 0.5px $padding * 1px $padding * 0.1px $padding * 1px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;

      &.selected {
        background-color: $color-list-selection;
      }
    }

    .pill {
      margin-right: $padding * 0.5px;
    }
  }

  .emptyResults {
    padding: $padding * 1px;
    color: $color-standard-border;
  }

  .currentUsers,
  .addRemoveUsers {
    flex-flow: row wrap;
  }

  .section {
    overflow: hidden;
    animation: expand-vertical $animation-full-duration forwards;

    &.closing {
      animation: contract-vertical $animation-full-duration forwards;
    }
  }

  .addRemoveUsers {
    .pill {
      border-radius: $radius * 2px;
      overflow: hidden;
      animation: expand-horizontal $animation-half-duration forwards;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      &.removing {
        animation: contract-horizontal $animation-half-duration forwards,
                contract-pill $animation-half-duration forwards;
      }
    }
  }

  .actionsContainer {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;

    .saveButton {
      height: 24 + $padding * 1px;
      cursor: pointer;
      border: none;
      border-radius: $radius * 2px;
      padding: 0 $padding * 1px;
      background-color: $color-ht-range-end;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@keyframes contract-pill {
  0% {
    margin-right: $padding * 0.5px;
  }
  100% {
    margin-right: 0;
  }
}
