@import '../../styles/global.module';

.fullWidth,
.pageWidth {
  position: relative;
  margin: 0;
  padding: 0;
}

.fullWidth {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.pageWidth {
  margin: 0 $padding + px;
}

.row,
.column {
  display: flex;
  justify-content: stretch;
  flex-grow: 1;
}

.row {
  flex-flow: row;
}

.column {
  flex-flow: column;
}

.column:not(:first-child) {
  margin-left: $padding * 2px;
}

@media ($hiResDesktop) {
  .fullWidth {
    align-items: center;
  }

  .pageWidth {
    width: 100%;
    max-width: $hiResDesktopMinSize;
  }
}
