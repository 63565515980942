@import "../../styles/global.module";

.container {
  user-select: none;
  width: 100%;
  position: relative;
  margin-bottom: $padding * 2px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.container:after {
  content: " ";
  border-bottom: 1px solid $color-pill;
  position: fixed;
  left: 0;
  right: 0;
  margin-top: 48px;
  z-index: -1;
}

.tab {
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  min-width: 200px;
  padding: 0 $padding * 4px;
  border-radius: $radius * 1px $radius * 1px 0 0;
  text-transform: uppercase;
}

.selected {
  background-color: $color-pill;
}
