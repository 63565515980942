@import "../../styles/global.module";

.container {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  align-items: stretch;

  .listTitle {
    @extend .body;
    margin-bottom: $padding * 1px;
    text-transform: capitalize;
  }

  .section {
    overflow: hidden;
    animation: expand-vertical $animation-full-duration forwards;

    &.closing {
      animation: contract-vertical $animation-full-duration forwards;
    }
  }

  .pickerContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: $padding * 1px;
  }

  .timePicker {
    padding-left: $padding * 1px;
  }

  .actionsContainer {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: flex-end;

    .saveButton {
      height: 24 + $padding * 1px;
      cursor: pointer;
      border: none;
      border-radius: $radius * 2px;
      padding: 0 $padding * 1px;
      background-color: $color-ht-range-end;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
