@import "../../styles/global.module";

.navigationBar {
  overflow: hidden;
  padding-top: $padding * 2.2px;
  padding-bottom: $padding * 2.2px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  flex-grow: 1;
  background-color: $color-header-background;
  border-right: 1px solid $color-divider;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  animation: init 0.1s forwards;
  z-index: $z-index-modal;

  .spacer {
    flex-grow: 1;
  }

  .item {
    cursor: pointer;
    margin: $padding * 1px $padding * 1px 0 0;
    padding: $padding * 0.5px;
    padding-left: $padding * 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: $color-icon;
    background: linear-gradient(
                    90deg,
                    $color-ht-range-end 0%,
                    $color-ht-range-end 50%,
                    $color-header-background 50%,
                    $color-header-background 100%
    );
    background-size: 200% 200%;
    background-position-x: 100%;
    animation: slide-out 0.1s forwards;

    .help {
      @extend .heading;
      font-size: 20px;
      font-weight: bolder;
      margin: 0;
      width: 32px;
      height: 32px;
      border-radius: $radius * 4px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background-color: $color-icon;
      color: $color-page-background;
    }

    &:hover,
    &.highlighted {
      fill: $color-page-background;
      border-radius: 0 $radius * 0.625px $radius * 0.625px 0;
      animation: slide-in 0.1s forwards;
    }

    @keyframes init {
      0% {
        left: -100px;
      }
      50% {
        left: -100px;
      }
      100% {
        left: 0;
      }
    }

    @keyframes slide-in {
      0% {
        background-position-x: 100%;
      }
      100% {
        background-position-x: 0;
      }
    }

    @keyframes slide-out {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 100%;
      }
    }
  }
}
