@import "../../styles/global.module";

.container {
  margin-top: 96px;
  margin-right: 96px;
  margin-left: 96px;
}

.pageHeading {
  font-size: 60px;
  font-weight: 500;
  color: $color-text;
}

.bodyContainer {
  columns: 2;
  margin-top: 64px;
  height: 438px;
}

.textContainer {
  width: 408px;
  padding-right: 74px;
  color: $color-text-grey;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.46;
}

.image {
  object-fit: contain;
}

.actionsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-content: stretch;
  margin-top: 115px;

  .sectionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .actionButtons {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    width: 33%;
    justify-content: flex-end;
  }
}

.nextButton {
  margin-right: $padding * 10px;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  color: $color-ht-range-start;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.46;
  cursor: pointer;
}

.settingsButton {
  margin-right: $padding * 10px;
  padding: 9.9px 9px 14.1px 8px;
  border-radius: 10px;
  border-color: $color-ht-range-start;
  font-size: 24px;
  font-weight: 500;
  color: $color-page-background;
  background-color: $color-ht-range-start;
  cursor: pointer;
}

.roundButtons {
  width: 16px;
  height: 16px;
  margin: 0 30px 0 0;
  border-radius: 50%;
  background-color: $color-pill;
  border: none;
  cursor: pointer;

  &.selected {
    background-color: $color-ht-range-start;
  }
}

.closeButton {
  margin: 0 0 2px 2px;
  padding-top: 62px;
  padding-right: 62px;
  object-fit: contain;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
