@import "../../styles/global.module";

.container {
  min-width: 800px;
  padding: $padding * 3px;

  .graphHeading {
    @extend .heading;
    margin-bottom: $padding * 2px;
  }
}
