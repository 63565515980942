@import "../../styles/global.module";

.container {

  .list {
    margin-top: $padding * 1px;

    .listItem {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: $padding * 0.5px;

      .alertList {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        min-width: 180px;

        .lowerThanUsual,
        .didNotRespond,
        .lowerQuartile {
          @extend .bodySmall;
          padding: $padding * 0.25px $padding * 1px;
          border-radius: $radius * 3px;
        }

        .lowerThanUsual {
          background-color: $color-ht-range-start;
          color: $color-page-background;
        }

        .didNotRespond {
          background-color: $color-pill;
          color: $color-text;
        }

        .lowerQuartile {
          background-color: $color-ht-range-start;
          color: $color-page-background;
        }
      }
    }
  }
}
