@import "../../styles/global.module";

.container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-index-modal;
    padding: 144px 12px 12px 12px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .modal,
  .smallerModal,
  .autoSize {
    background-color: $color-page-background;
    position: relative;
    border-radius: $padding * 1px;
  }

  .modal {
    width: 100vw;
    height: 100vh;
    margin: 121px 47.7px 124px 107.3px;
    border-radius: $padding * 3px;
    box-shadow: 0 8px 24px 8px rgba(0, 0, 0, 0.1);
  }

  .smallerModal {
    width: 100vw;
    height: 20vh;
  }

  .modalTrigger {
    cursor: pointer;
    user-select: none;
    flex-grow: 1;
  }

  .closeButton {
    margin-top: $padding * 2px;
    margin-right: $padding * 4px;
    object-fit: contain;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .open .background {
    animation: fade-in 0.5s forwards;
  }

  .close .background {
    animation: fade-out 0.5s forwards;
  }

  .open .modal {
    animation: slide-up 0.2s forwards;
  }

  .close .modal {
    animation: slide-down 0.2s forwards;
  }

  .open .smallerModal {
    animation: slide-up 0.2s forwards;
  }

  .close .smallerModal {
    animation: slide-down 0.2s forwards;
  }

  @media ($tabletAndHigher) {
    .modal,
    .smallerModal,
    .autoSize {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    }

    .modal {
      width: 1086px;
      height: 894px;
      min-height: 40vh;
      max-height: 90vh;
      margin-top: -20vh;
    }

    .smallerModal {
      width: 638px;
      height: 170px;
      max-height: 90vh;
      margin-top: -20vh;
    }

    .autoSize {
      margin-top: -50vh;
    }

    .open .modal,
    .open .smallerModal,
    .open .autoSize {
      animation: zoom-in 0.5s forwards;
    }

    .close .modal,
    .close .smallerModal,
    .close .autoSize {
      animation: zoom-out 0.5s forwards;
    }
  }

  @keyframes slide-up {
    0% {
      margin-top: 200vh;
    }
    100% {
      margin-top: 0;
    }
  }

  @keyframes slide-down {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: 200vh;
    }
  }

  @keyframes fade-in {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes fade-out {
    0% {
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @keyframes zoom-in {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    50% {
      opacity: 1;
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes zoom-out {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
