@import "../../styles/global.module";

.container {
  width: 100%;
}

.tableRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 12px;
}

.dateCell,
.emptyCell,
.pendingCell,
.scoreCell {
  width: 70px;
  padding: 4px;
  text-align: center;
  margin-left: 4px;
}

.blankCell {
  flex-grow: 1;
}

.pendingCell,
.emptyCell {
  background-color: $color-ht-range-mid;
  align-self: stretch;
}

.pendingCell:before {
  content: "...";
  /* Insert clock icon here */
}

.dayName,
.date {
  text-transform: uppercase;
}

.blankCell,
.userLabel {
  min-width: 180px;
}
