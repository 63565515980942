@import '../../styles/global.module';

.header {
  margin: $padding * 3px 0 $padding * 2px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin-left: $padding * 1px;
  padding-top: $padding * 1px;
  padding-bottom: $padding * 1px;
  animation: init 0.25s forwards;
}

@keyframes init {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
