.pageHeading {
  font-size: 24px;
}

.heading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.subHeading {
  font-size: 12px;
  margin-bottom: 12px;
}

.body {
  font-size: 14px;
}

.bodySmall {
  font-size: 12px;
}

.bodyLarge {
  font-size: 18px;
}

.textInputLarge {
  font-size: 24px;
}

.allCaps {
  text-transform: uppercase;
}
