@import '../../styles/global.module';

.card {
  margin-bottom: $padding * 2px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 4px 4px 12px $color-shadow;
  padding: $padding * 1px;
  flex-grow: 1;

  &.variableHeight {
    flex-grow: unset;
  }
}
