@import "../../styles/global.module";

.userSelectorContainer {
  min-height: 500px;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  align-items: stretch;
}

.introduction {
  @extend .body;
  margin-top: $padding * 4px;
  margin-bottom: $padding * 3px;
}

.list {
  align-self: stretch;
  max-width: 50vw;
  margin-bottom: $padding * 2px;

  .listItems {
    display: flex;
    flex-flow: row wrap;

    .pill {
      margin-top: $padding * 1px;
      margin-right: $padding * 1px;
    }
  }
}
