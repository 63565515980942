@import '../../styles/global.module';

.link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: stretch;
  position: absolute;
  right: 0;
}

.linkUnderline {
  text-decoration: none;
}

.container {
  width: 97px;
  height: 26px;
  border-radius: 2.4px;
  background-color: $color-divider;
  text-decoration: none;
}

.wrapIconText {
  display: flex;
  flex-flow: row;
  padding: 5px 10px 6px;
}

.text {
  font-size: 12px;
  font-weight: 600;
  color: $color-heading;
}

.image {
  width: 12px;
  height: 12px;
  margin: 2px 4px 1px 0;
  object-fit: contain;
}
