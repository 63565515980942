@import "../../styles/global.module";

.showingResults {
  z-index: $z-index-modal;

  .input {
    z-index: $z-index-modal;
  }
}

.input {
  @extend .textInputLarge;
  position: relative;
  background-color: $color-page-background;
  padding: $padding * 1px;
  border-radius: $radius * 1px;
  border: 1px solid $color-standard-border;
  margin: 0 0 $padding * 1px 0;
  outline: none;

  &:focus {
    margin: -1px;
    margin-bottom: ($padding - 1) * 1px;
    border: 2px solid $color-ht-range-end;
  }
}

.popover {
  position: absolute;
  background-color: $color-page-background;
  border-radius: $radius * 1px;
  box-shadow: 4px 4px 12px $color-shadow;
  z-index: $z-index-modal;
  margin-top: $padding * -1px;
  min-width: 500px;
  overflow: hidden;
  animation: expand-vertical $animation-half-duration forwards;
}
