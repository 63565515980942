$duration: 0.5;

$animation-full-duration: $duration * 1s;
$animation-half-duration: $duration * 0.5s;

@keyframes expand-horizontal {
  0% {
    max-width: 0;
  }
  99.99% {
    max-width: 200px;
  }
  100% {
    max-width: unset;
  }
}

@keyframes contract-horizontal {
  0% {
    max-width: 200px;
  }
  100% {
    max-width: 0;
  }
}

@keyframes expand-vertical {
  0% {
    max-height: 0;
  }
  99.99% {
    max-height: 200px;
  }
  100% {
    max-height: unset;
  }
}

@keyframes contract-vertical {
  0% {
    max-height: 200px;
  }
  100% {
    max-height: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:export {
  fullDuration: $duration * 1000;
  halfDuration: $duration * 500;
}
