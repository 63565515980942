$color-page-background: #ffffff;
$color-header-background: #f9f9f9;
$color-shadow: rgba(0, 0, 0, 0.25);
$color-underlay: rgba(0, 0, 0, 0.1);
$color-icon: #bebec6;
$color-standard-border: #979797;
$color-pill: #d8d8d8;
$color-pill-new: #61a86b;
$color-divider: #e3e3e3;

$color-ht-range-start: #062977;
$color-ht-range-mid: #e2e2e2;
$color-ht-range-end: #f3b348;

$color-heading: #585858;
$color-text: #1d1d1d;
$color-list-selection: #d8d8d84c;
$color-text-grey: #707070;

$color-score-graph: #4b9e54;

:export {
  icon: $color-icon;
  pageBackground: $color-page-background;
  headerBackground: $color-header-background;
  divider: $color-divider;
  border: $color-standard-border;
  pill: $color-pill;
  pillNew: $color-pill-new;
  shadow: $color-shadow;
  htRangeStart: $color-ht-range-start;
  htRangeMid: $color-ht-range-mid;
  htRangeEnd: $color-ht-range-end;
  heading: $color-heading;
  text: $color-text;
  listSelection: $color-list-selection;
  greyText: $color-text-grey;
  scoreGraph: $color-score-graph;
}
