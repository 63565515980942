@import "../../styles/colors.module";

.container {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $color-header-background;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: $color-text;
  overflow: hidden;
}
