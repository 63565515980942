.userLabel {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.userName {
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
