@import "../../styles/global.module";

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 48px 148px;
}

.text {
  font-size: 24px;
  color: $color-heading;
}
