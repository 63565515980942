$radius: 8;
$padding: 12;
$minorPadding: $padding * 0.67;

$mobileMin: 375;
$mobileLargeMin: 420;
$tabletMin: 768;
$desktopMin: 1024;
$hiResDesktopMin: 1300; // standard is usually 1440, but going by design atm

$mobileSmallMax: $mobileMin - 1;
$mobileMax: $tabletMin - 1;
$mobileLargeMax: $mobileLargeMin - 1;
$tabletMax: $desktopMin - 1;
$desktopMax: $hiResDesktopMin - 1;

$mobileMinSize: $mobileMin + px;
$mobileLargeMinSize: $mobileLargeMin + px;
$tabletMinSize: $tabletMin + px;
$desktopMinSize: $desktopMin + px;
$hiResDesktopMinSize: $hiResDesktopMin * 1px;

$mobileSmallMaxSize: $mobileSmallMax + px;
$mobileMaxSize: $mobileMax + px;
$mobileLargeMaxSize: $mobileLargeMax + px;
$tabletMaxSize: $tabletMax + px;
$desktopMaxSize: $desktopMax + px;

$mobileSmallAndLower: 'max-width: ' + $mobileSmallMaxSize;
$mobileAndLower: "max-width: " + $mobileMaxSize;
$desktopAndHigher: "min-width: " + $desktopMinSize;
$hiResDesktop: "min-width: " + ($hiResDesktopMin + ($padding * 2)) * 1px;
$mobileLargeAndHigher: "min-width: " + $mobileLargeMaxSize;
$tabletAndHigher: "min-width: " + $tabletMinSize;
$tabletAndLower: "max-width: " + $tabletMaxSize;

:export {
  padding: $padding;
  minorPadding: $minorPadding;
  hiResDesktopMin: $hiResDesktopMin;
}
