@import "../../../styles/global.module";
.pill {
  user-select: none;
  padding: $padding * 0.25px;
  padding-right: $padding * 1px;
  border-radius: $radius * 2px;
  background-color: $color-pill;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.adding.modify {

  }

  &.adding.reverse {
    background-color: $color-pill-new;
    color: $color-page-background;
  }

  &.removing.modify {

  }

  &.removing.reverse {
    opacity: 0.5;
  }

  .label {
    flex-grow: 1;
  }

  .button {
    margin-left: $padding * 1px;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }
}
