@import "../../../../styles/global.module";

.container {
  border-radius: $radius * 3px;
  overflow: hidden;
}

.textContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.name {
  margin-top: 59px;
  font-size: 48px;
  color: $color-text;
}

.emoji {
  margin-top: 32px;
  font-size: 60px;
}

.welcome {
  margin-top: 32px;
  font-size: 24px;
  font-weight: 300;
  color: $color-text-grey;
}

.wrapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.startedButton {
  width: 204px;
  height: 53px;
  margin-top: 62px;
  padding: 9.9px 9px 14.1px 8px;
  border-radius: 10px;
  border-color: #062977;
  font-size: 24px;
  font-weight: 500;
  color: $color-page-background;
  background-color: #062977;
  cursor: pointer;
}

.notRightNowButton {
  cursor: pointer;
  margin-top: 16px;
  border-color: transparent;
  font-size: 18px;
  font-weight: 300;
  color: $color-text-grey;
  background-color: transparent;
}