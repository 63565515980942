@import "../../styles/global.module";

.container {
  margin: $padding * 3px $padding * 4px $padding * 2px $padding * 4px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .title {
    @extend .pageHeading;
    margin-bottom: $padding * 2px;
  }

  .message {
    @extend .bodyLarge;
    margin-bottom: $padding * 2px;
  }

  .actions {
    display: flex;
    flex-flow: row;
  }
}
