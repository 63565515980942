@import './colors.module';
@import './sizes.module';
@import './text.module';
@import './animation.module';
@import './misc.module';

* {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-page-background;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.invisibleUnderlay,
.modalUnderlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $z-index-modal - 1;
}

.modalUnderlay {
  background-color: $color-underlay;
  animation: fadeIn $animation-full-duration forwards;
}

.primaryButton {
  @extend .heading;
  text-transform: capitalize;
  min-width: 193px;
  min-height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: $color-ht-range-start;
  color: $color-page-background;
  border: none;
  border-radius: $radius * 1px;
  cursor: pointer;

  &:hover, &:active {
    opacity: 0.9;
  }
}

.secondaryButton {
  @extend .primaryButton;
  background-color: unset;
  color: $color-text-grey;
}
